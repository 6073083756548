// import iconImg from '~/images/marker.svg'

// called after GoogleMap is loaded, it is now compulsory to have some callback defined
window.initMap = () => {
  document.arrive('#map', { existing: true }, function(el) {
    var coords = new google.maps.LatLng(el.dataset.latitude, el.dataset.longitude)
    var opts = {
      auto_adjust: true,
      auto_zoom: false,
      center: coords,
      zoom: 16,
      styles: [
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#161E32"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#e62823"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#e4e3df"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "xvisibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "invert_lightness": false
                },
                {
                    "lightness": -100
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#e62823"
                }
            ]
        }
      ]
    }
    var map = new google.maps.Map(el, opts)
    // var marker = new google.maps.Marker({ position: coords, map: map, title: el.dataset.name, icon: {url: iconImg, scaledSize: new google.maps.Size(100,100)} })
    var marker = new google.maps.Marker({ position: coords, map: map, title: el.dataset.name })
    // var info = new google.maps.InfoWindow({ content: `<strong>${el.dataset.name}</strong><br>${el.dataset.address}` })
    // google.maps.event.addListener(marker, 'click', function () { info.open(map, marker) })
  })
}

